@import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&display=swap');
@import 'primeflex/primeflex.min.css';
@import 'assets/fonts/dps-icons.css';
@import 'functions';

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

a {
  color: inherit;
}

.dps-icon {
  display: inline-flex;
  font-size: 1.5rem;
  letter-spacing: normal;
}

// Bryntum styles overrides
.b-mask .b-mask-content {
  background-color: var(--p-primary-color);
}

.b-sch-canvas .b-sch-event-wrap {
  .b-sch-event {
    --event-opacity: 1;
    --event-border-radius: var(--p-border-radius-lg);
    --event-border-color: currentColor;
    --event-background-color: #{transparentizeColor(currentColor)};

    &:hover {
      --event-background-color: #{transparentizeColor(currentColor, 0.13)};
    }

    &-content {
      width: 100%;
      height: 100%;
    }
  }
}

dps-company-planning {
  .b-sch-canvas .b-sch-event-wrap .b-sch-event {
    color: #1e1864;
  }

  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover {
    background-color: #{transparentizeColor(#1e1864)} !important;
  }
}

dps-company-actuals {
  .b-sch-canvas .b-sch-event-wrap .b-sch-event {
    color: var(--p-gray-500);

    &.CONFIRMED,
    &.ABSENT {
      color: #1a862a;
    }
    &.PENDING {
      color: #f29120;
    }
    &.OVERDUE {
      color: var(--p-red-500);
    }
  }
}

/* Customizations to the designer theme should be defined here */
$smallScreenSize: 425px;

@media screen and (max-width: $smallScreenSize) {
  :root {
    font-size: 15px;

    // Disable inputs autozoom on iOS
    input {
      font-size: 16px;
    }
  }

  .p-toast {
    width: 100%;
    left: 0;
    top: 0;
    padding: 1rem;
  }
}

:root {
  --p-button-label-font-weight: 500 !important;
  --p-floatlabel-font-weight: 400 !important;
  --p-datatable-row-hover-background: #{transparentizeColor(var(--p-primary-color))} !important;
}

.p-iconfield .p-inputicon {
  --p-icon-size: 1.5rem;
}

.p-tabs {
  --p-tabs-tablist-border-width: 0 0 1px;

  .p-tab {
    --p-tabs-tab-border-width: 0 0 2px 0;
    --p-tabs-tab-background: none;
    --p-tabs-tab-margin: 0 0 -1px;

    font-weight: normal;

    &-active {
      font-weight: 500;
    }
  }
}

.p-fieldset .p-fieldset-legend {
  $x-spacing: 0.5rem;

  margin: 0 -#{$x-spacing};
  --p-fieldset-legend-background: transparent;
  --p-fieldset-legend-border-width: 0;
  --p-fieldset-legend-padding: 0 #{$x-spacing};
  --p-fieldset-legend-font-weight: 500;
}

.p-step-title {
  font-size: 1rem;
}

// Workaround of bug when select button is disabled but selected value is not visible
.p-togglebutton:disabled.p-togglebutton-checked {
  background: var(--p-togglebutton-checked-background);
  color: var(--p-togglebutton-checked-color);
}
