@font-face {
    font-family: "dps-icons";
    src: url("./dps-icons.woff2?6f29cea4dc97a2b33bfed2e74df289d0") format("woff2");
}

.dps-icon:before {
    font-family: dps-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dps-icon.dps-icon-add:before {
    content: "\f101";
}
.dps-icon.dps-icon-arrow_back:before {
    content: "\f102";
}
.dps-icon.dps-icon-badge:before {
    content: "\f103";
}
.dps-icon.dps-icon-block:before {
    content: "\f104";
}
.dps-icon.dps-icon-building:before {
    content: "\f105";
}
.dps-icon.dps-icon-check:before {
    content: "\f106";
}
.dps-icon.dps-icon-chevron:before {
    content: "\f107";
}
.dps-icon.dps-icon-close:before {
    content: "\f108";
}
.dps-icon.dps-icon-contact_page:before {
    content: "\f109";
}
.dps-icon.dps-icon-copy:before {
    content: "\f10a";
}
.dps-icon.dps-icon-delete:before {
    content: "\f10b";
}
.dps-icon.dps-icon-download:before {
    content: "\f10c";
}
.dps-icon.dps-icon-edit:before {
    content: "\f10d";
}
.dps-icon.dps-icon-euro:before {
    content: "\f10e";
}
.dps-icon.dps-icon-event-note:before {
    content: "\f10f";
}
.dps-icon.dps-icon-group_add:before {
    content: "\f110";
}
.dps-icon.dps-icon-groups:before {
    content: "\f111";
}
.dps-icon.dps-icon-key:before {
    content: "\f112";
}
.dps-icon.dps-icon-language:before {
    content: "\f113";
}
.dps-icon.dps-icon-lock:before {
    content: "\f114";
}
.dps-icon.dps-icon-logout:before {
    content: "\f115";
}
.dps-icon.dps-icon-menu:before {
    content: "\f116";
}
.dps-icon.dps-icon-more:before {
    content: "\f117";
}
.dps-icon.dps-icon-person_add:before {
    content: "\f118";
}
.dps-icon.dps-icon-person:before {
    content: "\f119";
}
.dps-icon.dps-icon-place:before {
    content: "\f11a";
}
.dps-icon.dps-icon-search:before {
    content: "\f11b";
}
.dps-icon.dps-icon-timer:before {
    content: "\f11c";
}
.dps-icon.dps-icon-warning:before {
    content: "\f11d";
}
